import React from 'react'
const FeaturesList = [{
    id:0,
    icon: 'fa-pencil',
    title:'All Courses',
    class1:'not_visible'
},
{
    id:1,
    icon: 'fa-graduation-cap',
    title:'Virtual Classname',
    class1:'not_visible'
},
{
    id:2,
    icon:'fa-book',
    title:'Real Meeting',
    class1:'not_visible'
}];
export default FeaturesList;