import React from "react";
import { createContext, useContext, useMemo } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext(null);
export const useSocket = () => {
    const socket = useContext(SocketContext);
    return socket;
}
export const SocketProvider = (props) => {
    // const socket = useMemo(() => io("http://89.116.227.138:8000"), []);
    const socket = useMemo(() => io("https://livestreamserver.assamglammakeupacademy.com",[]), []);
    return (
        <SocketContext.Provider value={socket}>
            {props.children}
        </SocketContext.Provider>
    );

};