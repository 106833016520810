import React from 'react'
import choose1 from "../images/choose-us-image-01.png";
import choose2 from "../images/choose-us-image-02.png";
import choose3 from "../images/choose-us-image-03.png";
const WhyUs = () => {
    return (
        <>
            <section className="section why-us" data-section="section2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-heading">
                                <h2>Why Choose Assam Glam Makeup Studio?</h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div id='tabs'>
                                <ul>
                                    <li><a href='#tabs-1'>Best Education</a></li>
                                    <li><a href='#tabs-2'>Top Management</a></li>
                                    <li><a href='#tabs-3'>Quality Meeting</a></li>
                                </ul>
                                <section className='tabs-content'>
                                    <article id='tabs-1'>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={choose1} alt="" />
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Best Education</h4>
                                                <p>When it comes to makeup education, Assam Glam Makeup Academy takes the lead with its exceptional training and expertise.
                                                Assam Glam Makeup Academy is widely recognized as the prime choice for those looking for an outstanding makeup academy experience.
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                    <article id='tabs-2'>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={choose2} alt="" />
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Top Level</h4>
                                                <p>Assam Glam Makeup Academy is renowned for its exceptional team of instructors who specialize in providing top-notch makeup courses.</p>
                                                <p>At Assam Glam Makeup Academy, we pride ourselves on offering the finest instructors in the industry, ensuring that our makeup courses are of the highest quality.</p>
                                            </div>
                                        </div>
                                    </article>
                                    {/* <article id='tabs-3'>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src={choose3} alt="" />
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Quality Meeting</h4>
                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo labore eaque quo tenetur similique, omnis atque, vel sit ullam repellendus suscipit distinctio nam ratione sequi. Recusandae sint eveniet delectus dolorem, sed quasi aliquid itaque deserunt beatae saepe hic libero assumenda!</p>
                                            </div>
                                        </div>
                                    </article> */}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};
export default WhyUs;