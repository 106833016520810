import React, { createContext, useRef } from 'react'
import { useEffect, useState } from "react";
import axios from "axios";
import base_url from "../api/bootApi";
import Login from './Login';
import Cart from './Cart';
import self from '../images/self.jpg';
import sujatha from '../images/sujatha.jpg';
import madhumita from '../images/madhumita.jpg';
import professional from '../images/professional.jpg';
import userEvent from '@testing-library/user-event';
import Stream from '../livestream2/Stream';
import Navbar from './Navbar';
const Courses = ({ login, showLogout, setLogin, logout, setLogout, setSubscribedCourses1, setUnSubscribedCourses1, restoreState }) => {
    const headers = {
        'Cache-Control': 'no-cache',
        'Accept-Language': 'en',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
        //"Authorization": "Basic dXNlcjowM2VhN2JhYS1mMTQ0LTQ5YWMtOGFhMy02NDE4YWJiNzdhMTk=",

    };

    const config = {
        headers
    };
    const [restoreState1, setRestoreState1] = useState(false);
    const [courses, setCourses] = useState([]);
    const [subscribedCourses, setSubscribedCourses] = useState([]);
    const [unSubscribedCourses, setUnSubscribedCourses] = useState([]);
    const [loginDetails, setLoginDetails] = useState({});
    const [courses3, setCourses3] = useState([]);
    const [courses2, setCourses2] = useState([]);
    const [changeCourse, setChangeCourse] = useState(false);
    const [showSubscribe, setShowSubscribe] = useState(true);
    const [showAddToCart, setShowAddToCart] = useState(false);
    const [showAddedToCart, setShowAddedToCart] = useState(false);
    const [loginDisplay, setLoginDisplay] = useState(false);
    const [disableSubscribe, setDisableSubscribe] = useState(false);
    const [disableAddToCart, setDisableAddTocart] = useState(false);
    const [addToCart, setAddToCart] = useState(false);
    const [displayName, setDisplayName] = useState(false);
    const [displayCart, setDisplayCart] = useState(false);
    const [cartToggle, setCartToggle] = useState(false);
    const [greetName, setGreetName] = useState("");
    const [cartItems, setCartItems] = useState([]);
    const [cartItems1, setCartItems1] = useState(() => { return JSON.parse(localStorage.getItem("items1")) });
    const [nitems, setNitems] = useState(0);
    const [nitems1, setNitems1] = useState(Number(localStorage.getItem("nItems")));
    const [courses4, setCourses4] = useState(() => { return JSON.parse(localStorage.getItem("courses")) });
    const [coursesMenu, setCoursesMenu] = useState(false);
    const [initialiseCourse,setInitialiseCourse] = useState(false);
    //  const CoursesContext = createContext();
    // const CoursesProvider = () =>{
    //     const [courses4,setCourses4] = useState(courses);
    //     return(<CoursesContext.Provider value={courses4}>

    //     </CoursesContext.Provider>)
    // }
    const getAllCourses = () => {
        axios.get(`${base_url}/courses`)
            .then((response) => {
                setCourses(response.data);
                console.log(response);
            },
                (err) => {
                    console.log("error : " + err);

                })
    };



    const subscribeClick = () => {
        setLoginDisplay(true);
        setDisableSubscribe(true);
        setShowSubscribe(false);
    };
    const loginHide = () => {
        setLoginDisplay(false);
        setDisableSubscribe(false);
        setShowSubscribe(true);
        setLogin(false);
    };
    const displayAddToCart = () => {
        setAddToCart(true);
        setShowSubscribe(false);
        setDisableSubscribe(false);
    };
    const greeting = (name) => {
        console.log(name);
        setGreetName(name);
        setDisplayName(true);
    };
    const addItem = (id) => {
        setRestoreState1(false);
        const courses1 = courses.map((course) => {

            if (course.id === id) {
                console.log("reached");
                return {
                    ...course,
                    addedToCart: true,
                };




                // setClass2("not_visible");

            }
            else {
                return course;
            }



        })
        setCourses(courses1);
        console.log("id :" + id);

        var addedCourse = courses.filter(c => c.id === id);
        console.log(addedCourse[0]);
        const nextCourse = [
            ...cartItems,
            addedCourse[0]
            // {
            //     // id: addedCourse[0].id,
            //     // title: addedCourse[0].title,
            //     // price: addedCourse[0].price
            //     // ...addedCourse[0],
            //     // title1:"hello"
            // }
        ];
        setCartItems(nextCourse);
        console.log(cartItems);

        setNitems(prevVal => prevVal + 1);
        setAddToCart(false);
        setShowAddedToCart(true);

        setChangeCourse(true);
        courses.map(c => {
            console.log(c.addedToCart);
        })




        //console.log(localStorage.getItem("items1"));
    }

    const removeItems = (id) => {
        setRestoreState1(false);
        const courses1 = courses.map((course) => {

            if (course.id === id) {
                console.log("reached");
                return {
                    ...course,
                    addedToCart: false,
                };




                // setClass2("not_visible");

            }
            else {
                return course;
            }



        })
        setCourses(courses1);
        setCartItems(cartItems.filter(c => c.id !== id));
        setNitems(prevValue => prevValue - 1);
    }
    const showCart = () => {
        setCartToggle(true);
        setDisplayCart(true);
        setAddToCart(false);
        setDisableAddTocart(true);
        console.log("inside cart");
        console.log(displayCart);
        setShowAddedToCart(false);
    };
    const backToCourses = () => {
        setDisplayCart(false);
        setDisableAddTocart(false);
        setShowAddedToCart(true);
    };
    const getLoginDetails = (loginDetails) => {
        setLoginDetails(loginDetails);
    }
    const updateCourses = (coursess) => {
        console.log(coursess);
        setCourses3(coursess);
        console.log(courses3);
    };
    const afterPayment = () => {
        setCartItems([]);
        setNitems(0);
        setDisplayCart(false);
        setDisableAddTocart(false);
        setAddToCart(true);
    };
    const displayLogout = () => {
        showLogout();
    };
    // useEffect(()=>{
    //     console.log(loginDetails);
    // },[loginDetails]);
    const setLogoutState = () => {
        axios.get(`${base_url}/courses`).then((response) => {
            setCourses(response.data);
        },(err)=>{
            console.log(err);
        });
        setDisplayName(false);
        setDisplayCart(false);
        setShowSubscribe(true);
        setAddToCart(false);
        setDisableAddTocart(false);
        setShowAddedToCart(false);
        setLogout(false);
    }
    useEffect(() => {
        cartToggle ? setDisplayCart(true) : setDisplayCart(false);
    }, [cartToggle]);

    useEffect(() => {
        if (!restoreState) {
            getAllCourses();

        }
        else {
            console.log(JSON.parse(localStorage.getItem("courses")));
        }
        setRestoreState1(restoreState);
        // setShowAddedToCart(true);
        setAddToCart(false);
    }, []);
    useEffect(() => {
        if (restoreState1) {
            setDisplayName(true);
            setShowSubscribe(false);

            setGreetName(localStorage.getItem("name"));
            setNitems(Number(localStorage.getItem("nItems")));
            const items2 = JSON.parse(localStorage.getItem("items1"));

            setCartItems(cartItems1);
            setNitems(nitems1);
            setCourses(courses4);
            setShowAddedToCart(true);
            console.log(courses4);



        }
        else {
            console.log("reached");
        }

    }, [restoreState1]);

    useEffect(() => {
        setCourses2(courses);
        console.log(courses);
        console.log(courses4);
        setSubscribedCourses(courses.filter(course => course.enrolled));
        setUnSubscribedCourses(courses.filter(course => !course.enrolled));
        //localStorage.removeItem("courses");
        localStorage.setItem("courses", JSON.stringify(courses));
        //localStorage.setItem("subscribedCourses", JSON.stringify(courses));



    }, [courses]);
    useEffect(() => {
        // localStorage.removeItem("subscribedCourses");
        console.log(subscribedCourses);
        if(subscribedCourses.length > 0){
             localStorage.setItem("subscribedCourses",
            JSON.stringify(subscribedCourses));
        }
        // localStorage.setItem("subscribedCourses",
        //     JSON.stringify(subscribedCourses));
            console.log(JSON.parse(localStorage.getItem("subscribedCourses")));
        setSubscribedCourses1(JSON.parse(localStorage.getItem("subscribedCourses")));
        setUnSubscribedCourses1(unSubscribedCourses);
        console.log(subscribedCourses);
    }, [subscribedCourses]);
    useEffect(() => {
        setCourses(courses.map(course => {
            return{
                ...course,
                enrolled:false
            }
        }));
        setInitialiseCourse(true);
        // const updatedCourses = courses.map(c1 => {
        //     courses3.map(c2 => {
        //         if (c1.id === c2.id) {
        //             c1.enrolled = true;
        //         }
        //     })
        //     return c1;
        // });
        // setCourses(updatedCourses);
    }, [courses3]);
    useEffect(() => {
        // if (!restoreState1) {

        localStorage.setItem("items1", JSON.stringify(cartItems));
        //}
        console.log(restoreState1);
        console.log(localStorage.getItem("items1"));
    }, [cartItems]);
    useEffect(() => {
        // if (!restoreState1) {
        localStorage.setItem("nItems", nitems);
        //}


    }, [nitems]);
    useEffect(() => {
        if (login) {
            setLoginDisplay(true);
        }
    }, [login]);
    useEffect(() => {
        if (logout) {
            setLogoutState();
        }
    }, [logout])
    useEffect(()=>{
        if(initialiseCourse){
            const updatedCourses = courses.map(c1 => {
                courses3.map(c2 => {
                    if (c1.id === c2.id) {
                        c1.enrolled = true;
                    }
                })
                return c1;
            });
            setCourses(updatedCourses);
            setInitialiseCourse(false);
        }
    },[initialiseCourse]);
    return (

        <section className="section courses" data-section="section4">
            {coursesMenu ? <Stream coursesMenu={coursesMenu} /> : null}
            {displayName ? (<><button className="cart" onClick={() => {
                showCart();
            }}>Go to cart <i class="fa-solid fa-cart-shopping"></i> {nitems}</button><div className="greeting">Hi {greetName}</div></>) : null}
            {displayCart ? (<Cart cartItems={cartItems} removeItems={removeItems} backToCourses={backToCourses} loginDetails={loginDetails} updateCourses={updateCourses} afterPayment={afterPayment} />) : null}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-heading">
                            <h2>Choose Your Course</h2>
                        </div>
                    </div>

                    <div className="cointainer">
                        <div className="row">
                            <div className="login_width">
                                {loginDisplay ? (<Login loginHide={loginHide} displayAddToCart={displayAddToCart} greeting={greeting} getLoginDetails={getLoginDetails} updateCourses={updateCourses} restoreState1={restoreState1} displayLogout={displayLogout} />) : null}

                            </div>
                            {courses.map((course) => {
                                const { id, title, description, mainImage, subImage, price } = course;
                                return (
                                    <div className="col-md-6 mt-2">

                                        <div className="card" style={{ width: "100%" }}>
                                            {id === 1 ? (<img src={self} 
                                            className="card-img-top" alt="no image" />):(<img src={professional} 
                                            className="card-img-top" alt="no image" />)}
                                            <div className="card-body">
                                                <h5 className="card-title">{title}</h5>
                                                <p className="card-text">{description}</p>
                                                {showSubscribe ? (<button className="btn btn-primary mx-3"
                                                    onClick={() => {
                                                        subscribeClick();
                                                    }}>
                                                    Subscribe</button>) : null}
                                                {disableSubscribe ? (<button className="btn btn-primary mx-3"

                                                    disabled
                                                >
                                                    Subscribe</button>) : null}
                                                {addToCart ? course.enrolled ? (<span className="text-success">enrolled</span>) : (<button className="addToCartBtn" onClick={() => {
                                                    addItem(id);
                                                }}>Add To Cart</button>) : null}
                                                {disableAddToCart ? course.enrolled ? (<span className="text-success">enrolled</span>) : course.addedToCart ? (<span className="text-danger">added to cart</span>) : (<button className="addToCartBtn"
                                                    disabled

                                                >Add To Cart</button>) : null}
                                                {showAddedToCart ? course.enrolled ? (<span className="text-success">enrolled</span>) : course.addedToCart ? (<span className="text-danger">added to cart</span>) : (<button className="addToCartBtn" onClick={() => {
                                                    addItem(id);
                                                }}>Add To Cart</button>) : null}

                                                {id === 1 ? (<img src={sujatha} 
                                                style={{height:"50px", width:"50px"}}
                                                className="subimage1" alt="..." />):
                                                (<img src={madhumita} style={{height:"50px", width:"50px"}}
                                                className="subimage1" alt="..." />)}

                                                <span>Rs.{price}</span>
                                                
                                            </div>
                                        </div>
                                    </div>

                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

        </section>


    );
};

export default Courses;