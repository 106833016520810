import React from 'react'
import mainThumb from "../images/professional.jpg";
import video1 from "../videos/main_video.mp4";
const Video = () => {
    return (
        <>
            <section class="section video" data-section="section5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 align-self-center">
                            <div class="left-content">
                                <span>our presentation is for you</span>
                                <h4>Watch the video to learn more <em>about our Assam Glam Makeup Studio</em></h4>
                                <p>Explore the world of exquisite beauty transformations at Assam Glam Makeup Academy, an unparalleled institute renowned for its exceptional makeup artistry. As the epitome of excellence, this distinguished makeup academy provides top-notch training and ensures that you receive the best education in the field of makeup. From learning the latest trends to mastering professional techniques, Assam Glam Makeup Academy empowers aspiring makeup enthusiasts to become masters of their craft. Step into a realm where creativity knows no bounds and prepare to embark on an extraordinary journey towards a glamorous future.</p>
                                <br /><br /><p>The Assam Glam Makeup Academy stands out as the ultimate destination for anyone seeking the best makeup education..</p>
                                {/* <div class="main-button"><a rel="nofollow" href="#">LEARN MORE</a></div> */}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <article class="video-item">
                                <div class="video-caption">
                                    <h4>Assam Glam Makeup Studio</h4>
                                </div>
                                <figure>
                                    <a href={video1} class="play"><img src={mainThumb} /></a>
                                </figure>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}
export default Video;