import React from 'react';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import sujatha from '../images/sujatha.jpg';
import madhumita from '../images/madhumita.jpg';
import self from '../images/self.jpg';
import professional from '../images/professional.jpg';
const SubscribedCourses = ({ subscribedCourses, restoreStateTo }) => {
    const [subscribedCourses1, setSubscribedCourses1] = useState([]);
    useEffect(() => {
        restoreStateTo();
        const subscribedCoursesList = JSON.parse(localStorage.getItem("subscribedCourses"));
        console.log(subscribedCoursesList);
        if (subscribedCoursesList) {
            setSubscribedCourses1(subscribedCoursesList);

        }
        else {
            console.log(subscribedCourses);
            setSubscribedCourses1(subscribedCourses);
            localStorage.setItem("subscribedCourses",
                JSON.stringify(subscribedCourses));
        }

    }, []);
    return (<>
        <div className="container">
            <div className="row">
                {subscribedCourses1.length > 0 ? subscribedCourses1.map(course => {
                    const { id, title, description, mainImage, subImage, link } = course;
                    return (
                        
                            <div className="col-md-3 mt-2 ">

                                <div className="card mobile_subscribed" style={{ width: "18rem" }}>
                                    {id===1 ? (<img src={self} className="card-img-top" alt="..." />):(<img src={professional} className="card-img-top" alt="..." />)}
                                    
                                    <div className="card-body">
                                        <h5 className="card-title">{title}</h5>
                                        <p className="card-text">{description}</p>
                                        <button className="addToCartBtn"><a href={link}>
                                        Start Course
                                        </a>
                                            

                                        </button>


                                        {id === 1 ? (<img src={sujatha}
                                            style={{ height: "50px", width: "50px" }}
                                            className="subimage1" alt="..." />) :
                                            (<img src={madhumita} style={{ height: "50px", width: "50px" }}
                                                className="subimage1" alt="..." />)}
                                        <hr />
                                        <span>Live at :</span>< br/>
                                        {id===1 ? (<span>11:30 AM to 12:15 PM & 4:30 PM to 5:15 PM</span>):(<span>1:00 PM to 1:50 PM & 7:30 PM to 8:15 PM</span>)}




                                    </div>
                                </div>
                            </div>
                        
                    );
                }) : <h1>No Courses Subscribed</h1>}
            </div>
        </div>

    </>)
};
export default SubscribedCourses;