import React, { useState, useEffect } from "react";
import base_url from "../api/bootApi";
import axios from "axios";
import swal from "sweetalert";
const AdminRegister = ({ setShowAdminRegister, setShowAdmin }) => {
    const [admin, setAdmin] = useState({
        username: "",
        password: ""
    });
    const [errs, setErrs] = useState([]);
    const [errors, setErrors] = useState({
        username: "",
        password: ""
    });
    const formHandler = (e) => {
        e.preventDefault();

        register(admin);
    };
    const closeAdmin = () => {
        setShowAdminRegister(false);
        setShowAdmin(true);
    }
    const register = (data) => {
        setErrors({
            username: "",
            password: ""
        });
        //setRegister1("registering");
        axios.post(`${base_url}/adminRegister`, data)

            .then((response) => {
                console.log(response.data);
                setErrs(response.data);
                if (errs.includes("Name cannot be empty")) {
                    console.log("hello23");
                }


            }, (err) => {
                console.log("err :" + err);
            })
    }
    useEffect(() => {
        if (errs.includes("Username cannot be empty")) {
            setErrors((prevErr) => ({
                ...prevErr,
                username: "Username cannot be empty"
            }));


        }
        else {
            setErrors((prevErr) => ({
                ...prevErr,
                username: ""
            }));
        }
        if (errs.includes("Password cannot be empty")) {
            setErrors((prevErr) => ({
                ...prevErr,
                password: "Password cannot be empty"
            }));


        }
        else {
            setErrors((prevErr) => ({
                ...prevErr,
                password: ""
            }));
        }
        if (errs.includes("bad_cred")) {
            swal("Incomplete Credentials!", "Please fill in your credentials correctly!", "error");
        }



        if (errs.includes("no errors")) {

            //setRegister1("registered")
            setAdmin({
                username: "",
                password: ""
            });
            swal("Good Job!", "You are successfully registered", "success");


        }
    }, [errs])
    return (<>
        
        <form onSubmit={formHandler} className="adminLogin">
            <div className="container">
                <div className="row">

                    <h1 className="text-center">Admin Registration</h1>
                    <div className="col-md-12 mt-3">
                        <div className="form-group">

                            <input type="text"
                                className="form-control"
                                aria-describedby="emailHelp"
                                placeholder="Enter username"
                                value={admin.username}
                                onChange={(e) => {
                                    setAdmin({ ...admin, username: e.target.value });
                                }} />
                            <p>{errors.username}</p>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="form-group">
                            <input type="text"
                                className="form-control"
                                placeholder="Enter password"
                                value={admin.password}
                                onChange={(e) => {
                                    setAdmin({ ...admin, password: e.target.value });
                                }} />
                            <p>{errors.password}</p>
                        </div>
                    </div>
                </div>

            </div>


            {/* 
            <Link tag="a" className="list-group-item list-group-item-action" to="/adminLogin">
                Back to Admin Login
            </Link> */}
            <div className="container">
                <div className="row text-center">
                    <div className="col-12 col-md-6">
                        <button type="submit" className="btn btn-primary">Register</button>
                        
                    </div>
                    <div className="col-12 col-md-6">
                        
                        <button type="button" className="btn btn-secondary" onClick={closeAdmin}>Back to Admin Login</button>
                    </div>
                </div>
            </div>

        </form>

    </>);
};
export default AdminRegister;