import React, { useState,useEffect } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import Admin from './AdminLogin';
import AdminRegister from './AdminRegister';
const Navbar = ({setAdminCred, subscribedCourses,setSubscribedCourses,setLoginDisplay,setLogoutDisplay,
    logout1,setLogout1}) => {
    const [displayMenu, setDisplayMenu] = useState(false);
    const [login,setLogin] = useState(true);
    const [showAdmin,setShowAdmin] = useState(false);
    const [showAdminRegister,setShowAdminRegister] = useState(false);
    const adminLogin = () =>{
        console.log("hello");
        setShowAdmin(true);
    };
    const setAdminCred1 = (username,password) =>{
        setAdminCred(username,password);
    };
    useEffect(() =>{
        setLogin(!logout1);
        if(!logout1){
            localStorage.removeItem("subscribedCourses");
            setSubscribedCourses([]);
            
        }
    },[logout1]);
    useEffect(()=>{
        const subscribedCourses3 = JSON.parse(localStorage.
            getItem("subscribedCourses"));
            if(subscribedCourses3){
                setSubscribedCourses(subscribedCourses3);
            }
    },[])
    return (
        <>
            <header className="main-header clearfix head1" role="header">
                <div className="logo">
                    <img className="logo1 logoMain" src={logo} />
                </div>
                <a href="#menu" className="menu-link" onClick={() => {
                    setDisplayMenu(prev => !prev);
                }}><i className="fa fa-bars"></i></a>
                <nav id="menu" className={`main-menu ${!displayMenu ? 'menu1' : ''}`} role="navigation" onClick={() => {
                    setDisplayMenu(prev => !prev);
                }}>
                    <ul className="main-menu">
                        {logout1 ? (<li><Link tag="a" className="list-group-item list-group-item-action subscribe_menu" to="/subscribedCourses">
                            Subscribed Courses
                        </Link></li>):null}
                        
                        
                        <li><a href="#home">Home</a></li>
                        <li className="has-submenu"><a href="#section2">About Us</a>
                            <ul className="sub-menu">

                                <li><a href="#section3">What we do?</a></li>
                                <li><a href="#section3">How it works?</a></li>
                                {/* <li><a href="https://templatemo.com/about" rel="sponsored" className="external">External URL</a></li> */}
                            </ul>
                        </li>
                        <li><a href="#section4">Courses</a></li>
                        <li><a href="#register">Register</a></li>

                        <li><a href="#section6">Contact</a></li>
                        <li><a className="adminBtn" onClick={adminLogin}>Admin</a></li>
                        <li>{login ? (<a className="loginbtn" onClick={()=>{
                            setLoginDisplay();
                            
                        }}>Log in</a>):(<a className="logoutbtn" onClick={()=>{
                            setLogoutDisplay();
                            setLogin(true);
                            setLogout1(false);
                        }}>Log out</a>)}</li>
                        {/* <li><a href="" className="external">External</a></li> */}
                       {/*<li><Link tag="a" to="/developer">Developer</Link></li>*/}  
                    </ul>
                </nav>
                {showAdmin ? <Admin setShowAdminRegister={setShowAdminRegister} setAdminCred1={setAdminCred1} setShowAdmin={setShowAdmin} />:null}
                {showAdminRegister ? <AdminRegister setShowAdminRegister={setShowAdminRegister} setShowAdmin={setShowAdmin} />:null}
            </header>
           
        </>
    );
};
export default Navbar;