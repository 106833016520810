import React, { useContext, useEffect, useState } from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import axios from "axios";
import base_url from "../api/bootApi";
function getUrlParams(
    url = window.location.href
) {
    let urlStr = url.split('?')[1];
    return new URLSearchParams(urlStr);
}



const Stream = ({ admin }) => {
    const [courses, setCourses] = useState([]);
    const [showCourseMenu, setShowCourseMenu] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [selectedCourseName, setSelectedCourseName] = useState("Please select course from above menu");
    const [coursesMenu, setCoursesMenu] = useState(["---Select Course---"]);
    const [link, setLink] = useState("");
    const [files, setFiles] = useState(null);
    const [progress, setProgress] = useState({
        started:false,
        pc:0
    });
    const [msg,setMsg] = useState(null);
    useEffect(() => {
        console.log(admin);
        axios.get(`${base_url}/courses`)
            .then((response) => {
                setCourses(response.data);
                console.log(response);
            },
                (err) => {
                    console.log("error : " + err);

                })
        //console.log(subscribedCourses);
        //console.log(JSON.parse(localStorage.getItem("subscribedCourses")));
        //setSubscribedCourses1([]);

    }, []);
    const handleUpload = () => {
        if (!files) {
            setMsg("No file selected");
            swal("No File Selected!", "Please select files", "error");
        }
        else {
            const fd = new FormData();
            for(let i=0;i<files.length;i++){
                // fd.append(`file${i+1}`,files[i]);
                fd.append("files",files[i]);
            }
            setMsg("Uploading...");
            setProgress((prevState)=>{
                return{...prevState,started:true}
            });
            axios.post(`${base_url}/uploadVideo`, fd, {
                onUploadProgress: (progressEvent) => {
                    setProgress((prevState)=>{
                        return{...prevState,pc:progressEvent.progress*100}
                    });
                }
                // headers: {'Content-Type': 'multipart/form-data' }

            })
            .then((response) => {setMsg("Upload complete")})
            .catch(err=>{console.log(err)});
        }
    };
    // useEffect(()=>{
    //     setCoursesMenu(courses);
    // },[courses]);
    const courseMenuHandler = (id) => {
        setShowCourseMenu(prev => !prev);
        if (id) {
            setSelectedCourse(courses.filter((course) =>
                course.id === id
            ))
        }
        else {
            setSelectedCourseName("Please select course from above menu")
        }
    }
    const sendLink = () => {
        if (selectedCourseName === "Please select course from above menu") {
            swal("Incomplete Data!",
                "Please select a course!", "error");
        }
        else if (link === "") {
            swal("Incomplete Data !",
                "Please paste the link!", "error");
        }
        else {
            sendLinkOnServer({
                link: link,
                id: selectedCourse[0].id
            });
            console.log(selectedCourse);
            setLink("");
            swal("Link Sent Successfully!", "Your link is sent successfully to all your subscribers !! Now you can start your LIVE STREAM", "success");

        }
    }
    const sendLinkOnServer = (data) => {
        axios.post(`${base_url}/getlink`, data);
    }
    useEffect(() => {
        console.log(selectedCourse);
        if (selectedCourse[0]) {
            setSelectedCourseName(selectedCourse[0].title);
        }

    }, [selectedCourse]);
    useEffect(() => {
        if (showCourseMenu) {
            setCoursesMenu(["---Select Course---", ...courses]);
        }
        else {
            setCoursesMenu(["---Select Course---"]);
        }
    }, [showCourseMenu])
    function randomID(len) {
        let result = '';
        if (result) return result;
        var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
            maxPos = chars.length,
            i;
        len = len || 5;
        for (i = 0; i < len; i++) {
            result += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return result;
    }
    const roomID = getUrlParams().get('roomID') || randomID(5);
    let role_str = getUrlParams(window.location.href).get('role') || 'Host';
    const role =
        role_str === 'Host'
            ? ZegoUIKitPrebuilt.Host
            : role_str === 'Cohost'
                ? ZegoUIKitPrebuilt.Cohost
                : ZegoUIKitPrebuilt.Audience;

    let sharedLinks = [];
    // if (role === ZegoUIKitPrebuilt.Host || role === ZegoUIKitPrebuilt.Cohost) {
    //     sharedLinks.push({
    //         name: 'Join as co-host',
    //         url:
    //             window.location.protocol + '//' +
    //             window.location.host + window.location.pathname +
    //             '?roomID=' +
    //             roomID +
    //             '&role=Cohost',
    //     });
    // }
    sharedLinks.push({
        name: 'Join as audience',
        url:
            window.location.protocol + '//' +
            window.location.host + window.location.pathname +
            '?roomID=' +
            roomID +
            '&role=Audience',
    });
    // generate Kit Token
    const appID = 284761456;
    const serverSecret = "40bef77dfca2bfa42e7947529b0db50b";
    //console.log(roomId);
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, randomID(5), randomID(5));


    // start the call
    let myMeeting = async (element) => {
        if (!admin) {
            sharedLinks = [];
        }
        // Create instance object from Kit Token.
        const zp = ZegoUIKitPrebuilt.create(kitToken);
        // start the call
        zp.joinRoom({
            container: element,
            scenario: {
                mode: ZegoUIKitPrebuilt.LiveStreaming,
                config: {
                    role,
                },
            },
            sharedLinks,
        });
    };
    return (<>
        <div className="container mt-5 text-center">
            <div className="row">
                {
                    admin ? admin.username ? (<div className="col-md-4 c1">
                        {coursesMenu.map((course, index) => {
                            return (<>
                                <span key={index} className={course.title ? "span" : ""} onClick={() => {
                                    courseMenuHandler(course.id)
                                }}>{course.title ? course.title : course}  {!course.title ? (<i className="fa-solid fa-caret-down dropdown"></i>) : null}</span><hr />
                            </>)
                        })}
                        <div className="form-group">
                            <label for="exampleFormControlTextarea1">Selected Course</label>
                            <textarea className="form-control"
                                id="exampleFormControlTextarea1" rows="3"
                                value={selectedCourseName}
                                readOnly>

                            </textarea>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Link:</label>
                                <input type="text" className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    value={link}
                                    onChange={(e) => {
                                        setLink(e.target.value);
                                    }} />
                                <small id="emailHelp" className="form-text text-muted">Paste here the link given under join as audience section.</small>
                            </div>
                            <button className="sendlink" onClick={sendLink}>Send Link</button>
                        </div>
                        {/*<div className="upload">
                            <h1>Upload videos (You can also upload multiple videos at once)</h1>
                            <input type="file" onChange={(e)=>setFiles(e.target.files)} multiple />
                            <button onClick={handleUpload}>Upload</button>
                            {progress.started && <progress max="100" value={progress.pc}></progress>}
                                </div>*/}
                    </div>
                    ) : null : null

                }


                <div className="col-md-8">
                    <div ref={myMeeting}></div>
                </div>
                {!admin ? (<Link tagname="a" to="/subscribedCourses"><button className="btn btn-danger">Back</button></Link>) : null};
                <div className="backBtn">
                    <span>If you want to got to previous page press the above back button</span>
                </div>

            </div>

        </div>

    </>);
};
export default Stream;