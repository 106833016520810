import React, { useState } from 'react'
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Mainbanner from './components/Mainbanner';
import WhyUs from './components/WhyUs';
import Register from './components/Register';
import Video from './components/Video';
import Features from './components/Features';
import Contact from './components/Contact';
import Developer from './components/Developer';
import Courses from './components/Courses';
import SubscribedCourses from './components/SubscribedCourses';
import UnSubscribedCourses from './components/UnSubscribedCourses';
import Room from './livestream2/Room';
import Stream from './livestream2/Stream';
import Test from './components/Test1';
import axios from 'axios';
import base_url from './api/bootApi';
import './css/flex-slider.css';
import './css/fontawesome.css';
import './css/lightbox.css';
import './css/owl.css';

import './css/templatemo-grad-school.css';
import './css/style2.css';
import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import $ from "jquery";

function App() {
  const [subscribedCourses, setSubscribedCourses] = useState([]);
  const [unSubscribedCourses, setUnSubscribedCourses] = useState([]);
  const [restoreState, setRestoreState] = useState(false);
  const [email, setEmail] = useState("");
  const [room, setRoom] = useState("");
  const [login, setLogin] = useState(false);
  const [logout, setLogout] = useState(false);
  const [logout1, setLogout1] = useState(false);
  const [admin, setAdmin] = useState({
    username: "",
    password: ""
  });
  // const [courses5, setCourses5] = useState([]);
  // const greeting1 = (name1) => {
  //   greetName=name1;
  const setEmail1 = (mail) => {
    setEmail(mail);
  }
  const setRoom1 = (room) => {
    setRoom(room);
  }
  const setSubscribedCourses1 = (courses) => {
    console.log(courses);
    const subCourses = JSON.parse(localStorage.getItem("subscribedCourses"));
    if (subCourses) {
      setSubscribedCourses(subCourses);
    }
    else {
      setSubscribedCourses([]);
    }

    console.log(subscribedCourses);
  }
  const setUnSubscribedCourses1 = (courses) => {
    console.log(courses);
    setUnSubscribedCourses([...courses]);
    console.log(unSubscribedCourses);
  }
  const restoreStateTo = () => {
    if (logout1) {
      setRestoreState(true);
    }
    else {
      setRestoreState(false);
      // axios.get(`${base_url}/courses`).then((response) => {
      //   setC
      // });
    }

  }
  const setLoginDisplay = () => {
    setLogin(true);
    console.log("login display", login);
  }
  const setLogoutDisplay = () => {
    setLogout(true);
  }
  const showLogout = () => {
    setLogout1(true);
  }
  const setAdminCred = (username, password) => {
    setAdmin({
      username: username,
      password: password
    })
  }
  // useEffect(()=>{

  // },[su]);
  // useEffect(()=>{
  //   axios.get(`${base_url}/courses`)
  //   .then((response) => {
  //        setCourses5(response.data);
  //       console.log(response);
  //   },
  //       (err) => {
  //           console.log("error : " + err);
  //       })
  // },[]);
  return (
    <>

      <Routes>
        <Route path="/" element={[<Navbar setAdminCred={setAdminCred} logout1={logout1} setLogout1={setLogout1} setLoginDisplay={setLoginDisplay} setLogoutDisplay={setLogoutDisplay} subscribedCourses={subscribedCourses} setSubscribedCourses={setSubscribedCourses} />,
        (<div id="home"><Mainbanner /></div>),


        <Features />,
        <WhyUs />,
        (<div id="register"><Register /></div>),

        (<div id="section4"><Courses login={login}
          setLogin={setLogin} logout={logout} setLogout={setLogout}
          setSubscribedCourses1={setSubscribedCourses1}
          setUnSubscribedCourses1={setUnSubscribedCourses1}
          restoreState={restoreState} showLogout={showLogout} /></div>),
        <div id="section5">
          <Video />
        </div>
          ,
        (<div id="section6"><Contact /></div>)]} exact />

        <Route path="/developer" element={<Developer />} exact />
        <Route path="/golive" element={<Stream admin={admin} />} exact />
        {/* <Route path="/golive" element={<Sender setEmail1 = {setEmail1} setRoom1={setRoom1} />} exact /> */}
        <Route path="/room/:roomId" element={<Stream />} exact />
        {/* <Route path="/receive" element={<Receiver />} exact /> */}

        <Route path="/subscribedCourses" element={<SubscribedCourses subscribedCourses={subscribedCourses} restoreStateTo={restoreStateTo} />} exact />

        <Route path="/test" element={<Test />} exact />
      </Routes>
    </>
  );
}

export default App;
