import React from "react";
import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import base_url from "../api/bootApi";
const Admin = ({ setShowAdminRegister, setAdminCred1, setShowAdmin }) => {
    const [admin, setAdmin] = useState({
        username: "success",
        password: "success"
    });
    const [cred, setCred] = useState("");

    const linkRef = useRef(null);
    const closeAdmin1 = () => {
        setShowAdmin(false);
        setShowAdminRegister(true);
    }
    const getAdminLogin = (data) => {
        axios.post(`${base_url}/adminLogin`, data).
            then((response) => {
                console.log(response.data);
                setCred(response.data.credentials);

                const creden = response.data.credentials;
                if (creden === "bad credentials") {

                    swal("Invalid Credentials!",
                        "Please fill in your credentials correctly!", "error");
                }
                else{
                    setAdminCred1("success", "success");
                }
            }, (error) => {
                console.log(error);
            });
    }
    const formHandler = (e) => {
        e.preventDefault();
        getAdminLogin(admin);
        // if (!(admin.username === "admin")) {
        //     console.log(admin.username);
        //     swal("Invalid Username!",
        //         "Please fill in your username correctly!", "error");

        // }
        // else if (!(admin.password === "admin123")) {
        //     swal("Invalid Password!",
        //         "Please fill in your password correctly!", "error");
        // }
        // else {
        //     setAdminCred1(admin.username, admin.password);
        //     linkRef.current.click();
        // }
    };
    const closeAdmin = () => {
        setShowAdmin(false);
    }
    useEffect(() => {
        console.log(cred);
        // if (cred === "bad credentials") {

        //     swal("Invalid Credentials!",
        //         "Please fill in your credentials correctly!", "error");
        // }
        if (cred === "good credentials") {
            //setAdminCred1("success", "success");
            linkRef.current.click();

        }
    }, [cred])
    return (<>
        <form onSubmit={formHandler} className="adminLogin">
            <div className="container">
                <div className="row">
                    <button type="button" className="btn btn-success crossBtn2"
                        onClick={closeAdmin}><i class="fa-solid fa-xmark"></i></button>
                    <h1 className="text-center">Admin Login</h1>
                    <div className="col-md-12 mt-3">
                        <div className="form-group">

                            <input type="text"
                                className="form-control"
                                aria-describedby="emailHelp"
                                placeholder="Enter username"
                                value={admin.username}
                                onChange={(e) => {
                                    setAdmin({ ...admin, username: e.target.value });
                                }} />

                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="form-group">
                            <input type="password"
                                className="form-control"
                                placeholder="Enter password"
                                value={admin.password}
                                onChange={(e) => {
                                    setAdmin({ ...admin, password: e.target.value });
                                }} />
                        </div>
                    </div>
                </div>

            </div>



            <Link ref={linkRef} tag="a" className="list-group-item list-group-item-action" to="/golive">

            </Link>
            <div className="container mt-3">
                <div className="row text-center">
                    <div className="col-md-6">
                        <button type="submit" className="btn btn-primary text-center">Go Live</button>
                    </div>
                    <div className="col-md-6">
                        <button type="button" className="btn btn-secondary" onClick={closeAdmin1}>Register</button>
                    </div>
                </div>
            </div>



        </form>

    </>);
};
export default Admin;